.top-22 {
  top: -12px;
}
.videoTag {
  border: 2px solid #b6f0d5;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.faqCollape .ant-collapse-item {
  @apply mb-5;
}
.faqCollape,
.faqCollape .ant-collapse-item,
.faqCollape .ant-collapse-header,
.faqCollape .ant-collapse-content {
  border: none !important;
}
.faqCollape,
.faqCollape .ant-collapse-content {
  background: transparent;
}

.integrationVideo {
  border: 1px solid #d5d7da;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.videoPlayIcon {
  left: 47%;
  top: 85px;
}
.margin_top_10 {
  margin-top: -10px;
}
.pauseIcon{
  height: 80px;
  width: 80px;
  background: #898989;
  border-radius: 50%;
  padding: 20px;
}