.drawer-navigation {
  @apply border border-gray-300 rounded-xl shadow-md;
}

.drawer-navigation li {
  @apply py-2 px-4 rounded-full text-text-600;
}

.drawer-navigation .isActive {
  @apply bg-green-100 text-primary-700;
}

.drawer-navigation ul {
  @apply space-y-1;
}