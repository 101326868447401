/* Header styles */
header {
    @apply flex justify-center items-center py-[0.88rem] border-b border-solid border-[#e9e9eb] bg-white;
}

.main-header {
    @apply flex items-center justify-between w-full max-w-[1280px] md:px-5 lg:px-4 sm:px-2;
}

.main-header > * {
    @apply flex-1;
}

.main-header .logo img {
    @apply h-[2.5rem];
}

/* Tabs styles */
.main-header .tabs ul {
    @apply flex justify-center rounded-3xl bg-gray-100;
}

.main-header .tabs a {
    @apply flex items-center justify-center cursor-pointer rounded-3xl border border-solid border-transparent py-2 px-4 text-text-600;
}

.main-header .tabs .activeTab {
    @apply border border-solid border-[#a2d7bd] bg-[#f4f9f7] text-primary-600 shadow-sm;
}

/* Actions styles */
.main-header .actions {
    @apply flex justify-end;
}

/* Tabs wrapper styles */
.tabs-wrapper {
    @apply z-10 bg-black-100 rounded-md;
}

.tabs-wrapper ul {
    @apply flex justify-center;
}

.tabs-wrapper li {
    @apply border border-solid rounded-md px-3 py-2 text-nowrap text-primary-600;
}

.tabs-wrapper .activeTab {
    @apply bg-white w-full;
}

/* Profile Menu */
.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
    @apply shadow-md;
}
.ant-dropdown-menu-title-content > button {
    @apply gap-0 p-0;
}
.ant-btn-variant-link:not(:disabled):not(.ant-btn-disabled):hover {
    @apply text-gray-900;
}

.ant-segmented .ant-segmented-item {
    @apply rounded-full;
}
.ant-segmented .ant-segmented-group {
    @apply gap-1;
}