textarea {
  @apply relative flex w-full text-sm p-3 border border-solid border-[#D5D7DA] rounded-2xl shadow-sm transition duration-200 ease-in-out;
}

.grabber {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Optional background */
  user-select: none;
}