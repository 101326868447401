button.ant-btn {
  @apply rounded-full font-medium;
}

/* ------ PRIMARY ------ */
.ant-btn-variant-solid {
  border: 1px solid;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.17);
  @apply bg-[#279964] bg-gradient-to-b to-transparent p-5 border-primary-500 text-white drop-shadow-sm;
}
.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
  @apply bg-primary-600;
}

/* PRIMARY-DANGER ------ */
.ant-btn-color-dangerous.ant-btn-variant-solid {
  @apply bg-[#B42318] bg-gradient-to-b to-transparent outline-danger-500 text-white p-5 border-danger-400 border-opacity-40 shadow-[inset_0_1px_2px_0_#0a0c120c] drop-shadow-sm;
}
.ant-btn-color-dangerous.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
  @apply bg-danger-600;
}

/* ------ DEFAULT ------ */
.ant-btn-variant-outlined,
.ant-btn-variant-dashed {
  @apply p-5 text-[#414651] border-gray-400 shadow-[inset_0_-1px_0_0.25px_#0a0c120c] drop-shadow-sm;
}
.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  @apply border-gray-500 text-[#17181c];
}
/* DEFAULT-DANGER ------ */
.ant-btn-color-dangerous.ant-btn-variant-outlined,
.ant-btn-color-dangerous.ant-btn-variant-dashed {
  @apply border-red-500 text-[#B42318] shadow-[inset_0_-1px_0_0.25px_#0a0c120c] p-5 drop-shadow-sm;
}
/* DEFAULT-PRIMARY ------ */
.ant-btn-color-primary.ant-btn-variant-outlined,
.ant-btn-color-primary.ant-btn-variant-dashed {
  @apply border-primary-500 text-primary-500 shadow-[inset_0_-1px_0_0.25px_#0a0c120c] p-5 drop-shadow-sm;
}
.ant-btn-color-primary.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-color-primary.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  @apply border-primary-600 text-primary-600;
}


/* ------ TEXT ------ */
.ant-btn-color-primary.ant-btn-variant-text {
  @apply text-primary-500 drop-shadow-sm p-0;
}
.ant-btn-color-primary.ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {
  @apply text-primary-600 bg-transparent;
}
.ant-btn-color-dangerous.ant-btn-variant-text {
  @apply text-[#B42318];
}
.ant-btn-color-dangerous.ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {
  @apply text-[hsl(4,76%,60%)] bg-transparent;
}

/* ------ CIRCLE ------ */
.ant-btn.ant-btn-circle.ant-btn {
  @apply p-0;
}
