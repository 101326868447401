@import '@mantine/core/styles.css';

.barChart {
  .mantine-BarChart-bar {
    @apply fill-gray-400 hover:fill-orange-600 transition-all duration-100 ease-linear;
  }

  .tooltip-wrapper   {
    @apply bg-gray-800 text-white;
  }
}
