.badge-pill {
  line-height: 1;
  @apply flex flex-row items-center justify-between p-1 border-2 rounded-full text-sm font-medium transition-colors;
}

.prompt-desc {
  @apply border shadow-md p-3.5 border-gray-200 rounded-md text-text-600 text-sm;
  ol, ul {
    @apply list-disc pl-5 py-2;
  }
  p {
    @apply mb-2;
  }
  b, strong {
    @apply font-bold;
  }
}