*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Albert Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
}


ul {
  list-style: none;
}

button {
  background-color: transparent;
  /* border: none; */
  /* cursor: pointer; */
}

/* INPUT */
input:disabled,
textarea:disabled {
  color: #777;
  cursor: not-allowed;
  background-color: white;
}
input,
textarea {
  border: none;
  outline: none;
  font-family: 'Albert Sans', sans-serif;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote,
q {
  quotes: none;
  font-family: 'Albert Sans', sans-serif;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: bold;
  font-family: 'Albert Sans', sans-serif;
  letter-spacing: -0.02em;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
  font-weight: 500;
}

h5 {
  font-weight: 500;
}

p,
li,
dd,
dt,
th,
td {
  margin: 0;
  font-family: 'Albert Sans', sans-serif;
  color: #535862;
}

address {
  font-style: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Albert Sans', sans-serif;
}

select,
select:focus {
  appearance: none;
  outline: none;
  font-family: 'Albert Sans', sans-serif;
}

label {
  font-family: 'Albert Sans', sans-serif;
}





.page-content {
  @apply pt-[15px] pb-24 px-4 max-w-[1280px] mx-auto md:pt-6 md:pb-[37px] md:px-6 lg:px-4;
}
.page-min-height {
  min-height: calc(var(--vh, 1vh) * 100 - 56px);
}

.sub-heading {
  @apply font-medium text-[#181D27];
}

.divider-bottom {
  @apply border-b-2 border-solid border-gray-200;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-500);
  border-radius: 10px;
  transition: background-color .3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-300);
}

@media (hover:hover) {
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb:hover {
    display: block;
  }
}

@media (hover:none) {
  ::-webkit-scrollbar {
    display: block;
  }
}

.mobileinputCode{
  border-width: 1px;
    height: 3rem;
    border-right: none;
    width: 60px;
    border-radius: 9999px 0px 0px 9999px;
    margin-right: -15px;
    padding: 12px;
}

/* ANTD default TABLE override */
.ant-table-wrapper .ant-table {
  @apply border-2 border-gray-200 rounded-2xl;
}
[class^="ant-table"] {
  @apply border-none;
}
.ant-spin-nested-loading .ant-spin-container {
  @apply border border-gray-300 rounded-2xl;
}
.ant-table-wrapper .ant-table .ant-table-title,
.ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 16px 16px 0 0;
}
.ant-table-wrapper .ant-table-footer {
  @apply bg-white rounded-b-2xl;
}
.ant-table-wrapper .ant-table-row-expand-icon:focus,
.ant-table-wrapper .ant-table-row-expand-icon:hover,
.ant-table-wrapper .ant-table-row-expand-icon:active {
  @apply border-gray-500;
}
.ant-table-wrapper .ant-table-row-expand-icon {
  @apply border border-gray-300;
}
.ant-table-wrapper .ant-table-row-expand-icon::before,
.ant-table-wrapper .ant-table-row-expand-icon::after {
  @apply bg-gray-500;
}


/* ANTD default INPUT override */
.ant-input-outlined:focus,
.ant-input-outlined:focus-within,
.ant-input-outlined:hover {
  @apply border-gray-800;
}

/* ANTD default SELECT override */
.ant-select-single {
  @apply h-auto;
}
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
  @apply border-primary-400;
}

.ant-select .ant-select-arrow {
  @apply mr-1;
  svg {
    display: none;
  }
  &::after {
    content: url("./assets/images/icons/chevron-right.svg");
    transform: rotate(90deg);
  }
}
.ant-select-selector {
  @apply shadow-sm;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  @apply p-1.5 px-3 border border-gray-400 rounded-full items-center;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector:hover {
  @apply border-primary-500;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
  @apply bg-white text-gray-800 border border-gray-400 px-1.5 gap-1.5 rounded-md;
}

/* ANTD default MODAL override */
.ant-modal .ant-modal-content {
  @apply rounded-2xl p-0 overflow-scroll;
}
.ant-modal .ant-modal-close {
  @apply rounded-full;
}

/* ANTD default CHECKBOX override */
[class^="ant-checkbox"] [class^="ant-checkbox"] {
  @apply border-gray-400 bg-transparent;
}
.ant-checkbox-checked .ant-checkbox-inner {
  @apply bg-green-500 border-green-500;
}

/* ANTD default TABLE override */
.ant-table-wrapper .ant-table-footer {
  @apply flex justify-center;
}
.teamSwitch[aria-checked="true"]{
  background: #26BF77;
}
.borderFull{
  border-radius: 20px !important;
}