.input-wrapper {
  @apply flex gap-2 items-center p-3 w-full bg-white rounded-full border border-solid text-base shadow-sm;
  
  &:focus-within {
    @apply shadow-sm transition duration-150;
  }
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within,
.ant-input-outlined:hover {
  @apply border-gray-500 shadow-sm;
}
.ant-input {
  @apply text-base;
}

input {
  @apply flex-1 shrink gap-2 self-stretch my-auto w-full text-ellipsis text-primary-900;
}